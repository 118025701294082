/**
 * Combined by jsDelivr.
 * Original files:
 * - /gh/hifocus/merger@0.1.5/assets/js/jQuery/jquery.min.js
 * - /gh/hifocus/merger@0.1.5/assets/js/jquery-qrcode/jquery-qrcode.min.js
 * - /gh/hifocus/merger@0.1.5/assets/js/function.min.js
 * - /gh/hifocus/merger@0.1.5/assets/js/font_974919_xgtacm93vxp.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
