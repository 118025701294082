// HTML Template Merger - Merge your qrcode quicker. (https://github.com/hifocus/merger)
var client;var selected;document.getElementById("i").style.background="url('"+profile+"') no-repeat center/cover";var scale=window.devicePixelRatio;document.getElementById("titleinfo").innerHTML="惶心最帅";if(navigator.userAgent.match(/Alipay/i)){console.log("ALIPAY CLIENT");window.location.href=alipay;var title="";var subtitle="";removal()}
else if(navigator.userAgent.match(/MicroMessenger\//i)){if(location.href.substr(location.href.lastIndexOf('#')+1)=="showqrcode"&&!selected)document.getElementById('showqrcode').style.display="none";console.log("WECHAT CLIENT");client=wechat;document.getElementById("toclick").click();document.getElementById("titleinfo").innerHTML="长按识别二维码 向"+myname+"支付";var title="";var subtitle="";removal()}
else if(navigator.userAgent.match(/QQ\//i)){console.log("MOBILE QQ CLIENT");var title="";var subtitle="";removal();window.location.href=window.location.href.match(/^[^\#\?]+/)[0]+"#showqrcode";document.getElementById("titleinfo").innerHTML="长按识别二维码 向"+myname+"支付";document.getElementById("qrcontainer").removeChild(document.getElementById("currentqrcode"));document.getElementById("tenpayonly").src=qrcodeapi+urlencode(tenpay)}
else{if(location.href.substr(location.href.lastIndexOf('#')+1)=="showqrcode"&&!selected)document.getElementById('showqrcode').style.display="none";document.getElementById('qrcodeclose').onclick=function(){document.getElementById('currentqrcode').innerHTML="";if(document.getElementById('showqrcode').style.display=="flex")document.getElementById('showqrcode').style.display=""}}
if(/(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent)){console.log("MOBILE BROWSER CLIENT")}
else{console.log("DESKTOP BROWSER CLIENT")}
if(typeof paypal!=="string"){document.getElementById("depends").removeChild(document.getElementById("paypalbtn"));console.log("PAYPAL BUTTON REMOVED")}
else{function openbox(){selected="yes";window.open(paypal,"_blank","toolbar=no,scrollbars=no,visible=none,width=360,height=640")}
console.log("PAYPAL BUTTON OPERATING")}
function openwechat(){selected="yes";document.getElementById("titleinfo").innerHTML="微信扫一扫 向"+myname+"支付";client=wechat;showqrcode()}
function openalipay(){selected="yes";document.getElementById("titleinfo").innerHTML="支付宝扫一扫 向"+myname+"支付";client=alipay;showqrcode()}
function opentenpay(){selected="yes";document.getElementById("titleinfo").innerHTML="手机QQ扫一扫 向"+myname+"支付";client=tenpay;showqrcode()}
function removal(){document.getElementById("h").removeChild(document.getElementById("i"));document.getElementById("pending").removeChild(document.getElementById("depends"));document.getElementById("btncontainer").removeChild(document.getElementById("qrcodeclose"));console.log("REMOVAL DONE")}
function urlencode(String){return encodeURIComponent(String).replace(/'/g,"%27").replace(/"/g,"%22")}
document.getElementById("name").innerHTML=title;document.getElementById("description").innerHTML=subtitle;if(typeof debugmode!=="undefined"&&debugmode!==null){console.log("DEBUG MODE ENABLED ↑");console.log(`${'\n'} %c merger.html %c https://github.com/hifocus/merger ${'\n'}`,'color: #fadfa3; background: #030307; padding:5px 0;','background: #fadfa3; padding:5px 0;')}
else{console.clear();console.log("DEBUG MODE DISABLED ↑")}
function showqrcode(){if(location.href.substr(location.href.lastIndexOf('#')+1)=="showqrcode"&&selected=="yes")document.getElementById('showqrcode').style.display="flex";$("#currentqrcode").qrcode({render:"image",size:300*scale,text:client})}